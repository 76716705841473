import React, {FC, useEffect, useState} from "react";
import s from "./Payment.module.css"
import {AXI} from "../../libs/axi";
import {NavLink, useNavigate} from "react-router-dom";
import Recommendation from "../Main/Recommendation";
import Preloader from "../../components/Preloader";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import clsx from "clsx";
import Layout from "../../components/Layout";
import Icon from "../../components/Icon";
import moment from "moment";
import 'moment/locale/ru';
import useObjectModal from "../../hooks/useObjectModal";
import {Item} from "../../types/types";
import {TabsPayments} from "../../components/TabsPayments";
import {getPaymentsTable, getRestoranTable} from "../../api/restoran";
import {callService} from "../../utils/callService";
import {IS_NOT_AGE_18, IS_PAYMENT} from "../../utils/common";



interface PreodrderChilds {
    id: number
    name: string
    price: number
}

interface ItemObject {
    id: number;
    img: string;
    price: number,
    counter: number,
    name: string;
    m_item: MItems,
    preorderItemModifications: PreodrderChilds[] | []
}

interface Modification {
    maxprice: number,
    curprice: number,
    curdiscountprocent: number,
}

interface MItems {
    m_items_modification: Modification
}

interface ItemAfter {
    preorderItems: ItemObject[],
    summ: number,
    id: number,
    itemcount: number,
    createdAt: Date,
}


interface OrderItem {
    isHookah: boolean;
    isDifferent: boolean;
    isFastorder: boolean;
    restoranName: string;
}

export const Payment: FC<OrderItem> = ({isHookah, restoranName = '', isDifferent,isFastorder}) => {
    const navigate = useNavigate();
    const [isBar, setIsBar] = useState(true)

    const [itemAfter, setItemAfter] = useState({} as ItemAfter | null)
    const [isButDisabledCallpay, setIsButDisabledCallpay] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [activePayments, setActivePayments] = useState<number>(1)
    const [selectedItems, setSelectedItems] = useState<{ [key: number]: number }>({});
    const {searchText, modal} = useGetLocationData();

    // console.log(isButDisabledCallpay,'isButDisabledCallpay')

    const [table, setTable] = useState<string>("")
    const [isButDisabled, setIsButDisabled] = useState<boolean>(false)
console.log(isButDisabledCallpay,"sssss")
    useEffect(() => {
        setIsButDisabled(false)
    }, [])

    useEffect(() => {
        const savedData = localStorage.getItem('tableId');
        if (savedData) {
            setTable(JSON.parse(savedData));
        }
    }, []);


    useEffect(() => {
        const checkButtonState = (key: string, setDisabled: React.Dispatch<React.SetStateAction<boolean>>) => {
            const disableUntil = localStorage.getItem(key);
            if (disableUntil && Date.now() < Number(disableUntil)) {
                setDisabled(true);
                setTimeout(() => setDisabled(false), Number(disableUntil) - Date.now());
            }
        };

        checkButtonState('disable_callpay_until', setIsButDisabledCallpay);
    }, []);


    const handleSelectItem = (id: number, isChecked: boolean) => {
        setSelectedItems((prev) => {
            if (isChecked) {
                return {...prev, [id]: 1}; // По умолчанию выбираем 1
            } else {
                const updated = {...prev};
                delete updated[id];
                return updated;
            }
        });
    };
    const handleQuantityChange = (id: number, quantity: number) => {
        setSelectedItems((prev) => ({
            ...prev,
            [id]: quantity,
        }));
    };
    const calculateTotal = () => {
        return Object.entries(selectedItems).reduce((total, [id, quantity]) => {
            const item = itemAfter?.preorderItems.find((el) => el.id === Number(id));
            return total + (item ? item.price * quantity : 0);
        }, 0);
    };

    const handleIsOrder = async () => {
        try {
            const savedData: string | null | undefined = await localStorage.getItem('fastOrderId');

            const idIsFastOrder =  isFastorder && savedData && JSON.parse(savedData)

            console.log(idIsFastOrder,'idIsFastOrder')

            const response = await fetch(isFastorder ? `${AXI}/api/orders/preorderbyid?id=${Number(idIsFastOrder.id)}` : `${AXI}/api/orders/preorder?table=${Number(table)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();

            if (response.ok) {
                if (data) {
                    setItemAfter(data)
                    setIsLoading(false)
                } else {
                    navigate("/order")
                }

            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.log(error);
            if(isFastorder){
                navigate("/order")
            } else {
            navigate("/")

            }
        }
    };

    useEffect(() => {
        if (!!table) {
            handleIsOrder()
        }
    }, [table])

    const [isOne, setIsOne] = useState(false)
    useEffect(() => {
        if (!!modal) {
            setIsOne(true)
        }

        if (isOne) {
            window.location.reload()
        }
    }, [modal])

    const handleSaveRods = async () => {
        try {
            setIsButDisabled(true);

            const tablePayment = await getPaymentsTable(table).then((res: any) => res.data);

            if (tablePayment?.food?.linq?.formUrl) {
                window.location.href = tablePayment.food.linq.formUrl;
            } else {
                throw new Error('Form URL отсутствует в данных');
            }


        } catch (error) {
            console.error(error);
            alert('Ошибка при получении данных для оплаты');
        } finally {
            setIsButDisabled(false);
        }
    };


    const formattedTime = (orderTime: Date) => moment(orderTime).locale('ru').format('D MMMM YYYY в HH:mm');


    return (<Layout>
            {isLoading ? <Preloader/> : <div className={clsx(s.wrapper, {
                // [s.wrapperBack]: !isOrder && arrPrice && arrPrice.length === 0
            })}>
                <div className={s.titleOrder}>
                    <div className={s.wrapperTitle}>
                        <NavLink className={s.Arrow} to={"/"}>
                            <Icon.Arrow/>
                        </NavLink>
                        Счет на оплату
                    </div>
                    {/*{isDifferent && <TabsPayments active={activePayments} setActive={setActivePayments}/>}*/}
                    {/*<TabsPayments active={activePayments} setActive={setActivePayments}/>*/}
                </div>
                <div className={s.wrapLast}>
                    {activePayments === 1 ? itemAfter && itemAfter?.preorderItems?.length > 0 && itemAfter?.preorderItems?.map((el, index) =>
                        <div key={`${index}sss`} className={s.itemsBlock}>
                            <div className={s.item}>
                                <div className={s.blockRightOrder}>
                                    <div className={s.textOrder}>
                                        {el.name ? el.name : ""}
                                        <span> {el.counter > 1 ? !!el?.m_item?.m_items_modification?.curdiscountprocent ?
                                            <span className={s.discounts}>- {el.price} ₽ {" "}
                                                <span
                                                    className={s.pricesDisc}>
                                         - {el.m_item.m_items_modification.maxprice} ₽
                                      </span>
                                    </span> :

                                            <span>- {el.price} ₽ </span> : ""} - {el.counter} шт.</span>
                                        {el.preorderItemModifications?.length > 0 && el.preorderItemModifications.map((j, i) =>
                                            <div key={`${i}22ss`} className={s.textPreorder}>
                                                {j.name} {j.price ? `${j.price} ₽` : ""}
                                            </div>
                                        )}
                                    </div>

                                    <div className={s.sumOrder}>
                                        {!!el?.m_item?.m_items_modification?.curdiscountprocent ?
                                            <span className={s.discounts}>{el.price * el.counter} ₽ {" "}
                                                <span
                                                    className={s.pricesDisc}>
                                          {el.m_item.m_items_modification.maxprice * el.counter} ₽
                                      </span>
                                    </span> :
                                            <span>{el.price * el.counter} ₽ </span>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : itemAfter?.preorderItems?.map((el) => (
                        <div key={el.id} className={s.itemsBlock}>
                            <div className={s.item}>
                                <input
                                    type="checkbox"
                                    checked={!!selectedItems[el.id]}
                                    onChange={(e) => handleSelectItem(el.id, e.target.checked)}
                                />
                                <div className={s.blockRightOrder}>
                                    <div className={s.textOrder}>
                                        {el.name}
                                        <span>
                        {el.price} ₽ - {el.counter} шт.
                    </span>
                                    </div>
                                    {selectedItems[el.id] && (
                                        <input
                                            type="number"
                                            min={1}
                                            max={el.counter}
                                            value={selectedItems[el.id]}
                                            onChange={(e) =>
                                                handleQuantityChange(el.id, Number(e.target.value))
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={s.titleSostav}>
                    <div className={s.titleSostavs}>
                        <div className={s.titleTextSostavTog}>
                            <div>Итого</div>
                            <span>{itemAfter?.itemcount} позиции</span>
                        </div>
                        <div className={s.positionItog}>{itemAfter?.summ} ₽</div>
                    </div>
                </div>
                <div className={s.titleSostav2}>
                    <div className={s.titleSostavs2}>

                        <div className={s.titleTextSostavTog2}>
                            <div>Номер заказа</div>
                            <span>{itemAfter?.id}</span>
                        </div>
                    </div>
                </div>
                <div className={s.titleSostav3}>
                    <div className={s.titleTextSostavTog2}>
                        <div>Время заказа</div>
                        {itemAfter && <span>{formattedTime(itemAfter?.createdAt)}</span>}
                    </div>
                </div>

               <div className={s.btnFixed}>
                   {(IS_PAYMENT  === "true" ) &&
                       <>
                   <div className={s.sbp}>
                        <div className={s.nameSposob}>Способ оплаты</div>
                        <div className={s.nameSBP}>
                            СБП <Icon.SBP/>
                        </div>
                    </div>
                    <div className={s.flex}>
                        <div className={s.textBtnFixed}>
                            <div className={s.fistText}>Итого</div>
                            {activePayments === 1 ? <div> {itemAfter?.summ} ₽</div> :
                                <div>{calculateTotal()} ₽</div>}
                        </div>

                        {isBar ?
                            <NavLink className={s.ButtonOrder} to={"/kitchen-bar"}>
                                Оплатить
                            </NavLink>
                            : <div onClick={handleSaveRods} className={clsx(s.ButtonOrder, {
                                [s.disabled]: isButDisabled
                            })}>
                                Оплатить
                            </div>
                        }
                    </div>
                       </>
                    }
                    {!isFastorder &&
                    <>
                    <div className={s.sbp}>
                        <div className={s.nameSposob}>Способ оплаты</div>
                        <div className={s.nameSBP}>
                            Терминал/Наличные
                        </div>
                    </div>
                        <div className={s.flex}>
                        <div className={s.textBtnFixed}>
                        <div className={s.fistText}>Итого</div>
                        {activePayments === 1 ? <div> {itemAfter?.summ} ₽</div> :
                            <div>{calculateTotal()} ₽</div>}
                        </div>


                        {/*// <NavLink className={s.ButtonOrder} to={"/kitchen-bar"}>*/}
                        {/*//     Оплатить*/}
                        {/*// </NavLink>*/}
                        {/*// : <div onClick={handleSaveRods} className={clsx(s.ButtonOrder, {*/}
                        {/*//     [s.disabled]: isButDisabled*/}
                        {/*// })}>*/}
                        {/*//     Оплатить*/}
                        {/*// </div>*/}
                        <div onClick={() => callService('callpay', setIsButDisabledCallpay, table)}
                        className={clsx(s.ButtonOrder, {
                        [s.disabledOrange]: isButDisabledCallpay,
                        [s.disabled]: isButDisabled || isButDisabledCallpay
                    })}>
                        Рассчитаться
                        </div>

                        </div>
                        </>
                    }
                </div>


            </div>
            }
        </Layout>
    )
}