import {SearchForm} from "../../components/SearchForm";
import s from "./MenuPage.module.css";
import React, {useEffect, useMemo, useRef, useState} from "react";

import {AXI} from "../../libs/axi";
import ItemOrderMenu from "../../components/ItemOrderMenu";

import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import Layout from "../../components/Layout";
import TabsCat from "../../components/TabsCat";
import {ItemSlider} from "../../components/ItemSlider";
import Icon from "../../components/Icon";
import {NavLink} from "react-router-dom";
import useObjectModal from "../../hooks/useObjectModal";
import {Item} from "../../types/types";




interface ItemCat {
    id: number;
    img: string;
    description: string | null;
    name: string;
}

interface Item_m {
    id: number;
    maincategory: number;
    name?: string;
    img?: string;
    m_items: Item[]
}

interface Items {
    items: Item_m[]
    totalItems: number
    totalPages: number

}

export const MenuPage = () => {
    const [activeDrink, setActiveDrink] = useState(0)
    const [arrCat, setArrCat] = useState({} as Items)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {  modal,modalCategories,searchText } = useGetLocationData();
    // const [objectModal, setObjectModal] = useState<ObjectModal[]>([])
    const { objectModal, addObjectModalItem } = useObjectModal(searchText);
    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [activeDrink]);

    const setData = useSetLocationData();

    const handleClickRes = (id: number) => {
        addObjectModalItem(id,1)
    };

    useEffect(() => {
        let storedData = Number(localStorage.getItem('activeDrink'));

        if (storedData) {
            setActiveDrink(storedData);
        } else {
            localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
    }, [activeDrink]);


    useEffect(() => {
        setIsLoading(true);
        fetch(`${AXI}/api/items/menuitems?page=1&count=100`)
            .then(res => res.json())
            .then((data) => {
                    setArrCat(data as Items);
                    setIsLoading(false);
                },
            ).catch(error => {
            console.error('Error fetching data:', error);
            setIsLoading(false); // Устанавливаем isLoading в false в случае ошибки
        });

    }, [])

    const [categories, setCategories] = useState<string | null>("")


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const catValue = urlParams.get('categories');
        setCategories(catValue)
        if (catValue) {

            const targetElement = document.getElementById(catValue);
            if (targetElement) {
                window.scrollTo({
                    top: targetElement.offsetTop + 90,
                    behavior: 'smooth',
                });
            }
        }

    }, [arrCat, categories]);


    const handleMenuClick = (targetId: any) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop + 90,
                behavior: 'smooth',
            });
        }
    };

    let arrCatFilte = arrCat && arrCat.items && arrCat.items.filter(({maincategory}) => maincategory === activeDrink)


    useEffect(() => {

        const objectElements = Array.from(document.getElementsByClassName(s.titleGroup)) as HTMLElement[];
        const positions = objectElements.map((el) => el.offsetTop);
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const menu = menuRef.current;

            if (menu) {
                const currentIndex = positions && positions.findIndex((pos) => pos > scrollPosition);

                if (currentIndex !== -1) {

                    menu.scrollLeft = (currentIndex * (68 + 16)) - (68 + 16)

                }
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [arrCat]);

    const menuRef = useRef<HTMLDivElement>(null);
    const [objectPositions, setObjectPositions] = useState<number[]>([]);
    const [shouldScroll, setShouldScroll] = useState<boolean>(true);


    useEffect(() => {
        const menu = menuRef.current;
        const objectElements = Array.from(document.getElementsByClassName(s.titleGroup)) as HTMLElement[];

        const getObjectPositions = () => {
            const positions = objectElements.map((el) => el.offsetTop);
            setObjectPositions(positions);
        };

        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            if (menu && shouldScroll) {
                const currentIndex = objectPositions.findIndex((pos) => pos > scrollPosition);

                if (currentIndex !== -1) {
                    setShouldScroll(false);
                    menu.scrollTo({
                        left: currentIndex * window.innerWidth,
                        behavior: 'smooth',
                    });
                }
            }
        };


        getObjectPositions();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const memoizedTabsCat = useMemo(() => (
        <TabsCat activeDrink={activeDrink} setActiveDrink={setActiveDrink} />
    ), [activeDrink]);

    return (
        <Layout>
            <div>
                {(!modal && !modalCategories) && <div className={s.fixed}>

                    <SearchForm active={activeDrink} handleClickRes={handleClickRes} objectModal={objectModal}/>
                    <div className={s.tabs}>
                        {/*<TabsCat activeDrink={activeDrink} setActiveDrink={setActiveDrink}/>*/}
                        {memoizedTabsCat}
                    </div>
                </div>}
                {/*<div className={s.refMenu}>*/}
                {/*    <div className={s.wrapperCat}>*/}
                {/*        <div ref={menuRef} className={s.list}>*/}
                {/*            {arrCat?.items?.map(({id, name, img}) =>*/}
                {/*                <div className={s.itemCat} key={`${id}${name}`}>*/}
                {/*                    <button*/}
                {/*                        onClick={() => handleMenuClick(id)}*/}
                {/*                        className={s.menuButtonCat}*/}
                {/*                    >*/}
                {/*                        {img && <img*/}
                {/*                            src={   !img.includes('nophoto.png')*/}
                {/*                                ? `${img}`*/}
                {/*                                : `https://edabirja.ru/img/no-photos.png`}/>}*/}
                {/*                    </button>*/}
                {/*                    <div>{name}</div>*/}
                {/*                </div>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={s.wrapper}>
                    {arrCatFilte?.map((el) =>
                        <div className={s.wrapperItem} key={`${el.id}21`}>
                          <div className={s.wrapperTitle}>
                            <div id={String(el.id)} className={s.titleGroup}>{el["name"]} </div>
                              {el["m_items"].length > 6  &&
                              <button
                                  // to={`/categories/${el.id}`}
                                  onClick={()=> setData(["modalCategories",el.id])}
                                  className={s.showMoreButton} >
                                  Больше
                                  <Icon.ArrowMore/>
                              </button>}
                          </div>
                            <div className={s.wrapperGroup}>

                                {el["m_items"].length > 0 &&
                                <ItemSlider
                                    items={el.m_items}
                                    id={el.id}
                                    handleClickRes={handleClickRes}
                                    objectModal={objectModal}
                                />}
                            </div>
                        </div>)}
                </div>
            </div>
        </Layout>
    )
}