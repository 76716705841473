import s from "./ItemOrder.module.css";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import {AXI} from "../../libs/axi";
import Icon from "../Icon";
import ModalFastOrderItem from "../ModalFastOrderItem";


interface ObjectModal {
    id: number;
    count: number;
}
interface Childse{
    id:number
    name:string
    basicprice:number
}

interface Group{
    id:number,
    maxAmount:number,
    childModifiers:Childse[]
}

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount?: number | null,
    description?: string | null;
    discountprocent?: number | null;
    hot?: boolean | null;
    ccal?: number | null;
    mass?: number | null;
    small?: boolean;
    isSlider?: boolean;
    isSimiliar?: boolean;
    name: string;
    handleClickRes?: (id: number) => void;
    objectModal?: ObjectModal[];
    groupModifiers?: Group[],
}


const ItemOrder = (props: ItemCatigories) => {
    const {id, name, img, description, price, ccal,mass, discount, small, isSlider, handleClickRes, isSimiliar, objectModal, discountprocent, hot,groupModifiers} = props
    const setData = useSetLocationData();
    const { searchText } = useGetLocationData();
    const [isFastClick,setIsFastClick]=useState(false)
    const handleButtonClick = () => {
        setData(["modal", id,])
    };

    const isObjectModal = objectModal && objectModal.find((el)=> el.id === id)

    const handleFastClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        if(groupModifiers && groupModifiers.length > 0 ){
        // setIsFastClick(!isFastClick)
            handleButtonClick()
        } else {
                const savedData: string | null | undefined = localStorage.getItem('objectModal');
                const dataFast: ObjectModal[] =  savedData ? JSON.parse(savedData)  : []

            if(isObjectModal){
                setData(["searchText", searchText ? searchText + 1 : id + 1]);
                const remove = dataFast.filter((el)=> el.id !== id)

                console.log(remove,'remove')
                localStorage.setItem('objectModal', JSON.stringify(remove));
            } else {
                setData(["searchText", searchText ? searchText + 1 : id + 1]);
                const addFast = [...dataFast,{
                    id:id,
                    modification:[],
                    count: 1,
                }]

                console.log(addFast,'addFast')
                localStorage.setItem('objectModal', JSON.stringify(addFast));
            }
        }
    };


    return (
      <>  <div

            className={clsx(s.item, {
                [s.smallItem]: small,
                [s.similiar]: isSimiliar,
                [s.sliderItem]: isSlider
            })} key={`${id}${name}`}>
            <div className={s.wrap} onClick={handleButtonClick}>
                <div className={clsx(s.menuButtonCategory, {
                    [s.isSlider]: isSlider,
                })}>
                    {/*<img className={s.img} src={"https://edabirja.ru/img/no-photos.png"}/>*/}
                    {img
                        ? <img className={s.img}
                               src={AXI.includes("http://185.87.192.175:4000") ? `http://185.87.192.175${img}` : img}/>
                        : <div className={s.noFoto}> <Icon.NoFoto/><div>нет фото</div></div>

                    }
                    {hot && <div className={s.wrapIcon}>  <Icon.Hot/> </div>}
                    <div className={clsx(s.fast,{
                        [s.fastActive]: isObjectModal,
                        [s.notFastActive]: !isObjectModal
                    })} onClick={handleFastClick}> {isObjectModal ? <Icon.OrderActive/> : <Icon.Plus />} </div>
                </div>
                <div className={s.name}>{name}</div>
                {mass && <div className={s.gram}>{mass} гр</div>}
                <div className={s.price}>

                    {(discount || price === discountprocent) ?
                        <div className={s.discount}>
                           <span className={s.priceDisc}>{price} ₽</span>
                            <span className={s.disc}>{discount} ₽</span></div> :
                        <span> {price} ₽</span>

                    }
                </div>
            </div>
        </div>

          {isFastClick && <ModalFastOrderItem setIsFastClick={setIsFastClick} id={id} name={name} img={img} price={price} discount={discount || null} description={description || null}  groupModifiers={groupModifiers} hot={hot || null} ccal={ccal || null} mass={mass || null} />}
          </>
    )
}

export default ItemOrder